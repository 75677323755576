














































































































































































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Getter } from 'vuex-class'
import { Programme } from '@/modules/wallet/store'
import { ethers, FixedNumber } from 'ethers'

@Component({
  name: 'Network',
  components: {
    CudosToken
  }
})
export default class Network extends Vue {
  @Getter('allProgrammes', { namespace: 'wallet' }) allProgrammes!: Promise<Programme[]>
  @Getter('allContracts', { namespace: 'wallet' }) allContracts!: any
  @Getter('numberOfBlocksInADay', { namespace: 'wallet' }) numberOfBlocksInADay!: number
  @Getter('allActiveValidatorsWithSelf', { namespace: 'wallet' }) allActiveValidatorsWithSelf!: any

  programmes: Programme[] = []
  apr = '-'
  validators = []
  loading = true
  selectedId = ''

  async mounted (): Promise<void> {
    this.programmes = await this.allProgrammes
    this.validators = await this.allActiveValidatorsWithSelf
    if (this.programmes && this.programmes.length !== 0) {
      const blocksPerYear = FixedNumber.from(2399817)
      const tokensRewardPerBlock = await this.allContracts.stakingRewardContract.tokenRewardPerBlock()
      const apr = ((FixedNumber.fromValue(tokensRewardPerBlock).mulUnsafe(blocksPerYear)).divUnsafe(FixedNumber.from(this.programmes[0].totalStaked))).mulUnsafe(FixedNumber.from(100))
      this.apr = apr.toString()
    }
    this.loading = false
  }

  formatEther (gwei: any) {
    return ethers.utils.formatEther(gwei)
  }

  formatDays (blocks: any) {
    const days = Math.ceil(blocks / this.numberOfBlocksInADay)
    const months = Math.floor(days / 30)
    if (months === 0) {
      return `${days} Days`
    } else {
      const remainingDays = days - months * 30
      return `${months} Months, ${remainingDays} Days`
    }
  }
}
